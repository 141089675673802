import { CookieService } from 'ngx-cookie-service';
import { UsuarioDTO } from '../../models/controle-acesso/usuarioDTO';
import { STORAGE_KEYS } from './../../config/storage-keys.config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private cookieService: CookieService
  ) { }


  getUsuarioLogado() : UsuarioDTO {
    let str = localStorage.getItem(STORAGE_KEYS.usuarioLogado);
    if(str && str!=null){
      return JSON.parse(str);
    }else{
      return null;
    }
  }

  setUsuarioLogado(obj : UsuarioDTO){
    if(obj!=null){
      localStorage.setItem(STORAGE_KEYS.usuarioLogado,JSON.stringify(obj));
    }else{
      localStorage.removeItem(STORAGE_KEYS.usuarioLogado);
      this.setToken(null);
    }
  }


  getToken() : string {
    let str = localStorage.getItem(STORAGE_KEYS.token);

    if(str!=null){
      return str;
    }else{
      return null;
    }
  }

  setToken(token : string){
    if(token!=null){
      localStorage.setItem(STORAGE_KEYS.token,token);
      
      let duracaoToken = new Date();
      duracaoToken.setTime( duracaoToken.getTime() + 3*60*60*1000 ); //3h pra frente

      localStorage.setItem(STORAGE_KEYS.validadeToken,duracaoToken.toUTCString());
      
    }else{
      localStorage.removeItem(STORAGE_KEYS.token);
      localStorage.removeItem(STORAGE_KEYS.validadeToken);
    }
  }


  getValidadeToken(): string{
    return localStorage.getItem(STORAGE_KEYS.validadeToken);
  }
  

  setSessionID(sessionID : string){
    if(sessionID!=null){
      localStorage.setItem(STORAGE_KEYS.sessionID,sessionID);
            
    }else{
      localStorage.removeItem(STORAGE_KEYS.sessionID);
    }
  }


  getSessionID(){
    return localStorage.getItem(STORAGE_KEYS.sessionID);
  }

  setLastVisitedPage(url : string){
    if(url!=null){
      localStorage.setItem(STORAGE_KEYS.ultimaPaginaVisitada,url);
            
    }else{
      localStorage.removeItem(STORAGE_KEYS.ultimaPaginaVisitada);
    }
  }


  getLastVisitedPage() : string{
    return localStorage.getItem(STORAGE_KEYS.ultimaPaginaVisitada);
  }


  setTimeLastVisitedPage(timestamp : number){
    if(timestamp!=null){
      localStorage.setItem(STORAGE_KEYS.horaUltimaVisita,timestamp.toString());
            
    }else{
      localStorage.removeItem(STORAGE_KEYS.horaUltimaVisita);
    }
  }


  getTimeLastVisitedPage() : number{
    return Number(localStorage.getItem(STORAGE_KEYS.horaUltimaVisita));
  }


  setGeolocalizacaoVisitante(coords: any){
    if(coords!=null){
      localStorage.setItem(STORAGE_KEYS.coordsVisitante,JSON.stringify(coords));
            
    }else{
      localStorage.removeItem(STORAGE_KEYS.coordsVisitante);
    }
  }


  getGeolocalizacaoVisitante() : any{
    let obj = (localStorage.getItem(STORAGE_KEYS.coordsVisitante));

    if(obj && obj!=null){
      return JSON.parse(obj);
    }else{
      return null;
    }
  }


  setObject(key: string, object: any){
    if(object!=null){
      localStorage.setItem(key,JSON.stringify(object));
            
    }else{
      localStorage.removeItem(key);
    }
  }


  getObject(key:string) : any{
    let obj = (localStorage.getItem(key));

    if(obj && obj!=null){
      return JSON.parse(obj);
    }else{
      return null;
    }
  }
}
