import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../storage/storage.service';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class CookieManagerService {

  constructor( private cookieService: CookieService
             , private storageService: StorageService ) {

    

  }

  setCookie(key: string, value: string, days?: number) {

    // Convert 'days' to milliseconds. There are 86,400,000 milliseconds in a day.
    const expiryDate = days ? new Date(Date.now() + days * 86400000) : undefined;
    this.cookieService.set(key, value, { expires: expiryDate });

    //store the date of cookie registration
    this.setCookieDate(key, new Date());

    
  }

  getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  setAcceptCookies(
    value: boolean,
    tiposCookie: { necessarios: boolean, performance: boolean, funcionalidade: boolean, publicidade: boolean }
  ) {
    if (value === true) {
      // Define a data de expiração (ex: 1 ano)
      const expiryDays = 365;
      const date = new Date();
      date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
  
      // Cria um objeto com as preferências de cookies
      const cookiePreferences = {
        accepted: true,
        necessarios: tiposCookie.necessarios,
        performance: tiposCookie.performance,
        funcionalidade: tiposCookie.funcionalidade,
        publicidade: tiposCookie.publicidade
      };
  
      // Converte o objeto para JSON e codifica para armazenar no cookie
      const cookieValue = encodeURIComponent(JSON.stringify(cookiePreferences));
  
      // Armazena o cookie com o valor JSON contendo as preferências
      document.cookie = `accept-cookies=${cookieValue}; ${expires}; path=/`;

      sessionStorage.setItem('cookies-refused', 'false');

    } else {
      // Se não aceitar os cookies, armazena a informação na sessionStorage (será perdido após o fechamento do navegador)
      sessionStorage.setItem('cookies-refused', 'true');
    }
  }
  
  

  getAllCookiesAccepted(): boolean {
    const cookieVal = this.cookieService.get('accept-cookies');
    if (!cookieVal) {
      return false;
    }
    try {
      // Decodifica e converte o valor do cookie para objeto
      const cookieObj = JSON.parse(decodeURIComponent(cookieVal));
      // Retorna true somente se todas as flags estiverem true
      return cookieObj.accepted === true &&
             cookieObj.necessarios === true &&
             cookieObj.performance === true &&
             cookieObj.funcionalidade === true &&
             cookieObj.publicidade === true;
    } catch (error) {
      console.error('Erro ao fazer parse do cookie accept-cookies:', error);
      return false;
    }
  }
  
  
  getAcceptedCookiesTypes(): { 
    necessarios: boolean, 
    performance: boolean, 
    funcionalidade: boolean, 
    publicidade: boolean 
  } {
    const cookieVal = this.cookieService.get('accept-cookies');
    if (!cookieVal) {
      return { necessarios: false, performance: false, funcionalidade: false, publicidade: false };
    }
    try {
      // Converte o valor do cookie de volta para objeto
      const cookieObj = JSON.parse(decodeURIComponent(cookieVal));
      return {
        necessarios: cookieObj.necessarios === true,
        performance: cookieObj.performance === true,
        funcionalidade: cookieObj.funcionalidade === true,
        publicidade: cookieObj.publicidade === true
      };
    } catch (error) {
      console.error('Erro ao fazer parse do cookie accept-cookies:', error);
      return { necessarios: false, performance: false, funcionalidade: false, publicidade: false };
    }
  }
  

  getCookiesAcceptanceDate(): Date {
    const date = this.getCookieCreationDate('accept-cookies');

    if(date){
      return new Date(date);
    }

    return null;

  }


  private setCookieDate(key: string, date: Date) {
    this.cookieService.set(key+'_date', date.toISOString());
  }

  private getCookieCreationDate(key: string): Date {
      const value = this.cookieService.get(key+'_date');
      const date = value ? value : null;

      return date ? new Date(date) : null;
  }

  setLastVisitedPage(url: string, timestamp: number) {

    if(url=='/'){
      url = '/home'
    }
    try{
      //this.setCookie('last_visited_url', url);
      //this.setCookie('last_visited_time', timestamp.toString());

      this.storageService.setLastVisitedPage(url);
      this.storageService.setTimeLastVisitedPage(timestamp);
      
      
    }catch(err){
      console.log(err)
    }
  }

  // Retrieve the last visited page URL and visit time from cookies
  getLastVisitedPage(): any {
    /*return {
      url: this.getCookie('last_visited_url'),
      time: parseInt(this.getCookie('last_visited_time'), 10)
    };*/

    return {
      url: this.storageService.getLastVisitedPage(),
      time: this.storageService.getTimeLastVisitedPage(),
      latitude: this.storageService.getGeolocalizacaoVisitante().coords.latitude,
      longitude: this.storageService.getGeolocalizacaoVisitante().coords.longitude,
    };

  }

  setSessionId(sessionID: string){
    try{
      this.setCookie('session_id', sessionID);
      this.storageService.setSessionID(sessionID);
    }catch(err){
      console.log(err)
    }
  }

  getSessionId(): string{
    let sessionId = this.storageService.getSessionID();
    if (!sessionId) {
      sessionId = this.generateSessionId();
      this.setSessionId(sessionId);
    }
    return sessionId;
  }


  clearAllCookies() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }
  }


  private generateSessionId(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
 
  
  getLocation(): GeolocationPosition {
    return this.storageService.getGeolocalizacaoVisitante();
  }
}
