import { PopoverSelecionarDataComponent } from '../pages/modulo-gc/vestibular/components/popover-selecionar-data/popover-selecionar-data.component';
import { PopoverSelecionarPeriodoComponent } from '../pages/modulo-gc/vestibular/components/popover-selecionar-periodo/popover-selecionar-periodo.component';
import { BrMaskerModule } from 'br-mask';
import { PopoverSelecionarCursosVestibularComponent } from '../pages/modulo-gc/vestibular/components/popover-selecionar-cursos-vestibular/popover-selecionar-cursos-vestibular.component';
import { PopoverCriarSecaoPaginaComponent } from './../pages/modulo-gc/site/gc-cadastrar-pagina/components/popover-criar-secao-pagina/popover-criar-secao-pagina.component';
import { SwiperModule } from 'swiper/angular';
import { PopoverSelecionarNoticiaComponent } from './../pages/modulo-gc/site/gc-cadastrar-pagina/components/popover-selecionar-noticia/popover-selecionar-noticia.component';
import { PopoverDefinirCorPaginaComponent } from './../pages/modulo-gc/site/gc-cadastrar-pagina/components/popover-definir-cor-pagina/popover-definir-cor-pagina.component';
import { PopoverSelecionarTagsComponent } from './../pages/modulo-gc/comunicacao/gc-cadastrar-configuracao-template-email/components/popover-selecionar-tags/popover-selecionar-tags.component';
import { PopoverSelecionarStatusComponent } from './../pages/modulo-gc/comunicacao/gc-cadastrar-configuracao-template-email/components/popover-selecionar-status/popover-selecionar-status.component';
import { PopoverSelecionarFormasIngressoComponent } from './../pages/modulo-gc/comunicacao/gc-cadastrar-configuracao-template-email/components/popover-selecionar-formas-ingresso/popover-selecionar-formas-ingresso.component';
import { PopoverSelecionarCursosComponent } from './../pages/modulo-gc/comunicacao/gc-cadastrar-configuracao-template-email/components/popover-selecionar-cursos/popover-selecionar-cursos.component';
import { PopoverListarTemplatesEmailComponent } from './../pages/modulo-gc/comunicacao/gc-adm-templates-email/components/popover-listar-templates-email/popover-listar-templates-email.component';
import { PopoverListarInscricoesComponent } from './header-gc/components/popover-listar-inscricoes/popover-listar-inscricoes.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PopoverConfiguracaoDocumentoComponent } from './../pages/modulo-gc/documentacao/gc-cadastrar-parametro-analise-documentacao/components/popover-configuracao-documento/popover-configuracao-documento.component';
import { FooterComponent } from './footer/footer.component';
import { PopoverSaibaMaisComponent } from './../pages/listar-cursos/components/popover-saiba-mais/popover-saiba-mais.component';
import { PopupEditalComponent } from './../pages/detalhar-curso/components/popup-edital/popup-edital.component';
import { PopoverCompartilharComponent } from './../pages/detalhar-curso/components/popover-compartilhar/popover-compartilhar.component';
import { PopoverGradesCurricularesComponent } from './../pages/detalhar-curso/components/popover-grades-curriculares/popover-grades-curriculares.component';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { HeaderGcComponent } from './header-gc/header-gc.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogComponent } from './log/log.component';
import { PopoverAvaliarDocumentoMatriculaComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-avaliar-documento-matricula/popover-avaliar-documento-matricula.component';
import { PopoverEnviarEmailDocMatriculaPendenteComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-enviar-email-doc-matricula-pendente/popover-enviar-email-doc-matricula-pendente.component';
import { PopoverJustificarReprovacaoDocMatriculaComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-justificar-reprovacao-doc-matricula/popover-justificar-reprovacao-doc-matricula.component';
import { PopoverLogEnvioEmailMatriculaComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-log-envio-email-matricula/popover-log-envio-email-matricula.component';
import { PopoverLoginAssinarTermoMatriculaComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-login-assinar-termo-matricula/popover-login-assinar-termo-matricula.component';
import { PopoverSelecionarDocsMatriculaComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-selecionar-docs-matricula/popover-selecionar-docs-matricula.component';
import { PopoverTermoCompromissoMatriculaComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-termo-compromisso-matricula/popover-termo-compromisso-matricula.component';
import { PopoverAvaliarDocumentoInscricaoComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/components/popover-avaliar-documento-inscricao/popover-avaliar-documento-inscricao.component';
import { PopoverJustificarReprovacaoDocInscricaoComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/components/popover-justificar-reprovacao-doc-inscricao/popover-justificar-reprovacao-doc-inscricao.component';
import { PopoverTermoCompromissoInscricaoComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/components/popover-termo-compromisso-inscricao/popover-termo-compromisso-inscricao.component';
import { PopoverEnviarEmailDocInscricaoPendenteComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/components/popover-enviar-email-doc-inscricao-pendente/popover-enviar-email-doc-inscricao-pendente.component';
import { PopoverLogEnvioEmailInscricaoComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/components/popover-log-envio-email-inscricao/popover-log-envio-email-inscricao.component';
import { PopoverConfirmarDeferimentoInscricaoComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/components/popover-confirmar-deferimento-inscricao/popover-confirmar-deferimento-inscricao.component';
import { PopoverInformarNotasComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/components/popover-informar-notas/popover-informar-notas.component';
import { PopoverFiltrarCursosComponent } from '../pages/modulo-gc/vestibular/gc-classificar-candidatos/components/popover-filtrar-cursos/popover-filtrar-cursos.component';
import { PopoverInformarMatriculaComponent } from '../pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/components/popover-informar-matricula/popover-informar-matricula.component';
import { PopoverListarPagamentosInscricaoComponent } from '../pages/modulo-gc/vestibular/gc-listar-candidatos/components/popover-listar-pagamentos-inscricao/popover-listar-pagamentos-inscricao.component';
import { PopoverDefinirParametrosPaginaComponent } from '../pages/modulo-gc/site/gc-cadastrar-pagina/components/popover-definir-parametros-pagina/popover-definir-parametros-pagina.component';
import { CookiesConsentComponent } from './cookies-consent/cookies-consent.component';
import { PopoverMenuComponent } from './menu/popover-menu/popover-menu.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PopoverSelecionarSubstitutosComponent } from '../pages/modulo-gc/vestibular/components/popover-selecionar-substitutos/popover-selecionar-substitutos.component';
import { PopoverSelecionarCursoConvenioComponent } from '../pages/modulo-gc/convenio/components/popover-selecionar-curso-convenio/popover-selecionar-curso-convenio.component';
import { PopoverSelecionarUnidadeComponent } from '../pages/detalhar-curso/components/popover-selecionar-unidade/popover-selecionar-unidade.component';
import { PopoverFormularioPersonalizadoComponent } from '../pages/modulo-gc/site/gc-cadastrar-pagina/components/popover-formulario-personalizado/popover-formulario-personalizado/popover-formulario-personalizado.component';
import { PopoverSelecionarCursoFormularioComponent } from '../pages/modulo-gc/site/gc-cadastrar-pagina/components/popover-selecionar-curso-formulario/popover-selecionar-curso-formulario/popover-selecionar-curso-formulario.component';
import { CookieSettingsModalComponent } from './cookies-settings/cookie-settings-modal/cookie-settings-modal.component';




@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,   
    HeaderGcComponent,
    CookiesConsentComponent,
    CookieSettingsModalComponent,
    PopoverMenuComponent,

    //tela detalhar curso
    PopoverGradesCurricularesComponent,
    PopoverCompartilharComponent,
    PopoverSelecionarUnidadeComponent,

    PopupEditalComponent,
    PopoverSaibaMaisComponent,
    LogComponent,
    FooterComponent,
    PopoverConfiguracaoDocumentoComponent,
    PopoverListarInscricoesComponent,
    PopoverListarTemplatesEmailComponent,
    PopoverSelecionarCursosComponent,
    PopoverSelecionarCursosVestibularComponent,
    PopoverSelecionarFormasIngressoComponent,
    PopoverSelecionarStatusComponent,
    PopoverSelecionarTagsComponent,
    PopoverSelecionarPeriodoComponent,
    PopoverSelecionarDataComponent,   

    //tela de cadastro de páginas
    PopoverCriarSecaoPaginaComponent,
    PopoverDefinirCorPaginaComponent,
    PopoverSelecionarNoticiaComponent,
    PopoverDefinirParametrosPaginaComponent,
    PopoverFormularioPersonalizadoComponent,
    PopoverSelecionarCursoFormularioComponent,
    
    //tela de análise de docs de inscrição (gc-analisar-documentacao-inscricao)
    PopoverAvaliarDocumentoInscricaoComponent,
    PopoverJustificarReprovacaoDocInscricaoComponent,
    PopoverTermoCompromissoInscricaoComponent,
    PopoverEnviarEmailDocInscricaoPendenteComponent,
    PopoverLogEnvioEmailInscricaoComponent,
    PopoverConfirmarDeferimentoInscricaoComponent,
    PopoverInformarNotasComponent,
    
    //tela de análise de docs de matrícula (gc-analisar-documentacao-matricula)
    PopoverAvaliarDocumentoMatriculaComponent,
    PopoverEnviarEmailDocMatriculaPendenteComponent,
    PopoverJustificarReprovacaoDocMatriculaComponent,
    PopoverLogEnvioEmailMatriculaComponent,
    PopoverLoginAssinarTermoMatriculaComponent,
    PopoverSelecionarDocsMatriculaComponent,
    PopoverTermoCompromissoMatriculaComponent,
    PopoverInformarMatriculaComponent,
    
    PopoverFiltrarCursosComponent, //tela de classificação de candidatos

    PopoverListarPagamentosInscricaoComponent, //tela de listagem de candidatos
    
    

    //cadastro de vestibular
    PopoverSelecionarSubstitutosComponent,

    //cadastro de convenios
    PopoverSelecionarCursoConvenioComponent
  ],

  exports: [
    HeaderComponent,
    MenuComponent,
    HeaderGcComponent,

    //tela detalhar curso
    PopoverGradesCurricularesComponent,
    PopoverCompartilharComponent,
    PopoverSelecionarUnidadeComponent,

    PopupEditalComponent,   
    PopoverSaibaMaisComponent,
    FooterComponent,
    PopoverListarInscricoesComponent,
    PopoverListarTemplatesEmailComponent,
    PopoverSelecionarCursosComponent,
    PopoverSelecionarFormasIngressoComponent,
    PopoverSelecionarStatusComponent,
    PopoverSelecionarTagsComponent,
    
    //tela de cadastro de páginas
    PopoverCriarSecaoPaginaComponent,
    PopoverDefinirCorPaginaComponent,
    PopoverSelecionarNoticiaComponent,
    PopoverDefinirParametrosPaginaComponent,
    PopoverFormularioPersonalizadoComponent,
    PopoverSelecionarCursoFormularioComponent,

    PopoverSelecionarCursosVestibularComponent,
    PopoverSelecionarPeriodoComponent,
    PopoverSelecionarDataComponent,

    PopoverLogEnvioEmailInscricaoComponent,
    PopoverEnviarEmailDocInscricaoPendenteComponent,
    PopoverConfirmarDeferimentoInscricaoComponent,
    PopoverAvaliarDocumentoInscricaoComponent,
    PopoverJustificarReprovacaoDocInscricaoComponent,
    PopoverTermoCompromissoInscricaoComponent,
    PopoverInformarNotasComponent,
    
    PopoverAvaliarDocumentoMatriculaComponent,
    PopoverEnviarEmailDocMatriculaPendenteComponent,
    PopoverJustificarReprovacaoDocMatriculaComponent,
    PopoverLogEnvioEmailMatriculaComponent,
    PopoverLoginAssinarTermoMatriculaComponent,
    PopoverSelecionarDocsMatriculaComponent,
    PopoverTermoCompromissoMatriculaComponent,
    PopoverInformarMatriculaComponent,

    PopoverListarPagamentosInscricaoComponent,

    CookiesConsentComponent,
    PopoverMenuComponent,

    //cadastro de vestibular
    PopoverSelecionarSubstitutosComponent,

    //cadastro de convenios
    PopoverSelecionarCursoConvenioComponent
  ],

  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    QuillModule,
    CommonModule,
    FormsModule,
    QuillModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    SwiperModule,
    BrMaskerModule,
        
  ]
})
export class ComponentsModule { }
