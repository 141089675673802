<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title slot="start" class="title">Configurações de Cookies</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="cookie-settings">
    <p>
      Os cookies ajudam a melhorar sua experiência no site, lembrar preferências, personalizar conteúdo e garantir funcionalidades essenciais.
    </p>
    <ion-list>
      <!-- Cookie Necessário: sempre ativo -->
      <ion-item>
        <ion-label>&nbsp;Cookies Necessários</ion-label>
        <ion-checkbox slot="start" [(ngModel)]="tiposCookie.necessarios" disabled="true" checked="true" class="ion-no-margin"></ion-checkbox>
      </ion-item>
      <!-- Outras opções -->
      <ion-item>
        <ion-label>&nbsp;Cookies de Desempenho</ion-label>
        <ion-checkbox slot="start" [(ngModel)]="tiposCookie.performance" class="ion-no-margin"></ion-checkbox>
      </ion-item>
      <ion-item>
        <ion-label>&nbsp;&nbsp;Cookies de Funcionalidade</ion-label>
        <ion-checkbox slot="start" [(ngModel)]="tiposCookie.funcionalidade" class="ion-no-margin"></ion-checkbox>
      </ion-item>
      <ion-item>
        <ion-label>&nbsp;Cookies de Publicidade</ion-label>
        <ion-checkbox slot="start" [(ngModel)]="tiposCookie.publicidade" class="ion-no-margin"></ion-checkbox>
      </ion-item>
    </ion-list>
    

    <div class="buttons">

      <ion-button (click)="confirmSettings()">
        Aceitar
      </ion-button>
  
      <ion-button (click)="dismiss()">
        Fechar
      </ion-button>

    </div>
    
  </div>
</ion-content>
