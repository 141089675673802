import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cookie-settings-modal',
  templateUrl: './cookie-settings-modal.component.html',
  styleUrls: ['./cookie-settings-modal.component.scss'],
})
export class CookieSettingsModalComponent implements OnInit {

  // Configurações iniciais:
  // Cookies Necessários sempre ativos (aceitos), os demais iniciam como não aceitos.
  tiposCookie = {
    necessarios: true,
    performance: false,
    funcionalidade: false,
    publicidade: false
  };

  constructor(private modalCtrl: ModalController) {}
  
  ngOnInit(): void {
  }

  // Função para confirmar as configurações e fechar o modal,
  // retornando as preferências definidas.
  confirmSettings() {
    this.modalCtrl.dismiss(this.tiposCookie);
  }

  // Função para fechar o modal sem retornar nada.
  dismiss() {
    this.modalCtrl.dismiss(this.tiposCookie);
  }

}
