<div *ngIf="visivel" class="cookie-consent" [ngStyle]="{ 'background-color': configuracaoCookie.corFundo }">
  <div class="cookie-message">
    <quill-editor [(ngModel)]="configuracaoCookie.texto" disabled="true" placeholder="" class="text">
      <div quill-editor-toolbar style="display:none"></div>
    </quill-editor>
  </div>
  <div class="buttons">
    <div class="button-1">
      <!-- Botão "Política de Privacidade" -->
      <button [ngStyle]="{ 'background-color': configuracaoCookie.corBotaoPoliticaPrivacidade, 'color': configuracaoCookie.fonteBotaoPoliticaPrivacidade }"
              (click)="openDocument()">
        Política de Privacidade
      </button>

    </div>
    
    <div class="config-button">
      <ion-icon (click)="openSettings()" class="icon-button" name="settings-outline"></ion-icon>
    </div>
    
    
    <div class="buttons-2-3">

      <!-- Botão de Aceitar -->
      <button [ngStyle]="{ 'background-color': configuracaoCookie.corBotaoAceitar, 'color': configuracaoCookie.fonteBotaoAceitar }"
              (click)="acceptAll()">
        Aceitar Todos
      </button>

      <button [ngStyle]="{ 'background-color': configuracaoCookie.corBotaoAceitar, 'color': configuracaoCookie.fonteBotaoAceitar }"
              (click)="deny()">
        Recusar
      </button>

    </div>
    
  </div>

</div>
